<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm
          ref="searchRef"
          :fields="this.search.fields"
          :card="this.search.card"
          @change="searchChange"
        >
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-tabs>
          <b-tab title="Histórico de Predicciones">
            <historico-predicciones-tab :mdf_selected="mdf_selected"></historico-predicciones-tab>
          </b-tab>

          <b-tab title="Dashboard">
            <dashboard-tab :mdf_selected="mdf_selected" :fundos="fundos"></dashboard-tab>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </section>
</template>
          
          <script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


import Stat from '@/views/clients/base/components/Stat.vue'

import useApiServices from '@/services/useApiServices.js';

import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import DashboardTab from './tabs/DashboardTab.vue'
import HistoricoPrediccionesTab from './tabs/HistoricoPrediccionesTab.vue'


import axios from "@axios";
var qs = require('qs');

import
{
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,


    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    Stat,

    BOverlay,
    BSpinner,

    BrunaForm,
    BrunaElementLoader,


    DashboardTab,
    HistoricoPrediccionesTab



  },

  methods: {

    findFieldByName(fields, name)
    {
      return fields.find(field => field.id == name)
    },

    searchChange(field)
    {
      if (field.id == "mdf")
      {
        this.mdf_selected = field.value;

        let keys = Object.keys(this.searchResults[field.value]);
        keys.sort();

        this.fundos = keys

      }

    },

    loadSearch()
    {


      axios.request({
        method: "get",
        url: useApiServices.historicoData.search,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) =>
        {
          this.searchResults = response.data;


          /*
          let options = [
            { value: null, text: "Selecciona una opción" }
          ]

          let keys = Object.keys(this.searchResults);
          keys.forEach(item =>
          {
            if (item.trim() != "")
            {
              options.push({ value: item, text: item })
            }
          })

          let mdf_field = this.findFieldByName(this.search.fields, 'mdf')

          mdf_field.options = options;
*/
        })


    }


  },

  mounted()
  {
    this.loadSearch();
  },

  created()
  { 


  },


  data()
  {

    let debug = (this.$route.query.debug == '1')


    return {

      debug: debug,


      searchResults: [],
      mdf_selected: null,

      search: {
        card: {
          title: "Búsqueda",
          subTitle: "",
          tooltip: "",
          sidebarContent: {
            title: "Histórico de Predicciones",
            body: '<p>El Histórico de Predicciones contiene un resumen con todas las predicciones realizadas anteriormente, sus respectivas fechas, estados de la simulación y rendimientos predichos. En esta vista encontrarás las predicciones más actualizadas de los rendimientos (ton/ha) a nivel de turnos.</p> <p> Cada vez que se actualice un valor de riego, fertilización, biometría o clima asociado a un fundo-equipo-turno, se gatilla una predicción de rendimiento (ton/ha) que se verá en este lugar</p> <p> Se diseñaron 3 tipos de modelos que se aplican automáticamente y que dependen de la información disponible y están relacionados con los diferentes períodos de crecimiento.</p> <p> - <strong>CB0A0:</strong> Modelo a aplicar en turnos sin biometría ni información de azúcar. Este modelo está ajustado para aumentar la precisión en la predicción del crecimiento y del rendimiento del turno en el período previo a la primera biometría.</p> <p> - <strong>CB1A0:</strong> Modelo a aplicar en turnos que cuentan con información de biometría, pero no tienen información de azúcar.</p> <p> - <strong>CB1A1:</strong> Modelo a aplicar en turnos que ya cuentan con información de biometría y datos de azúcar.</p>'
          }
        },
        fields: [


          {
            label: "MDF",
            type: "select",
            id: 'mdf',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: "ML", text: "ML" }
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },



        ]
      },

      fundos: [],







    };
  },
};
</script>

<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
        
        
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
        
<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
          